import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Col, Row } from "react-bootstrap"

import FAQ from "../components/market-maker/faq"
import MarketMakerPricing from "../components/market-maker/market-maker-pricing"
import MmHowItWorks from "../components/market-maker/mm-how"
import MmFeatures from "../components/market-maker/features"
import MmHeader from "../components/market-maker/mm-header"

const MarketMaking = () => (
  <Layout pageInfo={{ pageName: "marketmaking" }}>
    <Seo
      title="Market Making"
      keywords={[`crypto`, `FIBot`, `defi`, `FIBot`, `cryptoFIBot`, `bot`]}
    />
    <Row className="g-0">
      <Col>
      <Container fluid className="g-0">
            <MmHeader />
        </Container>
      </Col>
    </Row>
    <Row className="g-0">
      <Col>
        <Container fluid className="g-0">
          <section id="mm-features">
            <MmFeatures />
          </section>
        </Container>
      </Col>
    </Row>
    <Row className="g-0">
      <Col>
        <Container className="mt-5">
          <section id="">
            <MmHowItWorks />
          </section>
        </Container>
      </Col>
    </Row>
    <Row className="g-0">
      <Col>
        <Container fluid className="g-0">
          <section id="mm-pricing">
            <MarketMakerPricing />
          </section>
        </Container>
      </Col>
    </Row>
    <Row className="g-0">
      <Col>
        <Container className="mt-5">
          <section id="faq">
            <FAQ />
          </section>
        </Container>
      </Col>
    </Row>
  </Layout>
)

export default MarketMaking;
