import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Col, Row, Button } from "react-bootstrap"
import { Link } from "gatsby"

function MarketMakerPricing() {
  return (
    <Container>
      <Row>
        <div className="display-4 col-12 heading mt-3 section-heading dark center">
          <span>Pricing</span>
        </div>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={4} sm={6} xs={12} className="">
          <ul className="pricing p-red">
            <li>
              <big>1 month</big>
            </li>
            <li>Volume strategy or liquidity strategy</li>
            <li>Unlimited transactions</li>
            <li>Trading intervals randomize</li>
            <li>Transaction reports</li>
            <li>Trading intervals from 1 transaction / minute</li>
            <li>Unlimited wallets (dex)</li>
            <li>Gas price protection (dex)</li>
            <li>Same day set up for available exchanges</li>
            <li>
              <h3>$500/strategy</h3>
            </li>
            <li>
              <Link to="https://telegram.me/FiBotDan">
                <button>Request bot</button>
              </Link>{" "}
            </li>
          </ul>
        </Col>

        <Col md={4} sm={6} xs={12} className="">
          <ul className="pricing p-blue">
            <li>
              <big>1 year</big>
            </li>
            <li>Volume strategy or liquidity strategy</li>
            <li>Unlimited transactions</li>
            <li>Trading intervals randomize</li>
            <li>Transaction reports</li>
            <li>Trading intervals from 1 transaction / minute</li>
            <li>Unlimited wallets (dex)</li>
            <li>Gas price protection (dex)</li>
            <li>Same day set up for available exchanges</li>
            <li>
              <h3>$4500/strategy</h3>
            </li>
            <li>
              <Link to="https://telegram.me/FiBotDan">
                <button>Request bot</button>
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default MarketMakerPricing
