import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Bell from "../../images/icons/bell.svg"
import Chart from "../../images/icons/chart.svg"
import Coin from "../../images/icons/coin.svg"
import Gas from "../../images/icons/gas.svg"
import Stopwatch from "../../images/icons/stopwatch.svg"
import Star from "../../images/icons/star.svg"


const MmFeatures = () => {
  return (
    <Container
      className="d-flex flex-column justify-content-center section feature-section"
    >
      <Row>
        <div className="display-4 col-12 heading mt-3 section-heading dark center">
          <span>Features</span>
        </div>
      </Row>
      <Row className="d-flex justify-content-center mt-4">
        <Col lg={4} md={8} className="d-flex flex-column mb-4 mm-feature-description">
          <Image
            className="mm-feature-image"
            src={Chart}
            alt="market making bot"
          />
          <h4>Natural Volume</h4>
          <p>
            FiBot works with hundreds of random wallets wallets at various intervals
            to generate natural volume. This keeps your charts healthy attracts attentions
            and can boost rankings.
          </p>
        </Col>
        <Col lg={4} md={8} className="d-flex flex-column mb-4 mm-feature-description">
          <Image
            className="mm-feature-image"
            src={Gas}
            alt="crypto price bot chart bsc eth heco defi price bot"
          />
          <h4>Gas Spike Protection</h4>
          <p>
            You can configure the maximum tax that you are willing to pay in gas for each
            transaction. If the gas price gets too high, the FiBot waits until the price comes back down.
          </p>
        </Col>
        <Col lg={4} md={8} className="d-flex flex-column mb-4 mm-feature-description">
          <Image
            className="mm-feature-image"
            src={Stopwatch}
            alt="custom crypto bot chart bsc eth heco defi price bot"
          />
          <h4>Random Intervals</h4>
          <p>
            FiBot continuously excutes buys and sells at random intervals. You can adjust the intervals according to your needs.
          </p>
        </Col>
        <Col lg={4} md={8} className="d-flex flex-column mb-4 mm-feature-description">
          <Image
            className="mm-feature-image"
            src={Coin}
            alt="custom crypto bot chart bsc eth heco defi price bot"
          />
          <h4>Random Amounts</h4>
          <p>Define the minimum and maximum amounts that you want each trade to be at. FiBot will only execute at random values within that range</p>
        </Col>
        <Col lg={4} md={8} className="d-flex flex-column mb-4 mm-feature-description">
          <Image
            className="mm-feature-image"
            src={Bell}
            alt="market making bot"
          />
          <h4>Realtime Notifications</h4>
          <p>
            FiBot will let you know everytime a transaction has been executed.
          </p>
        </Col>
        <Col lg={4} md={8} className="d-flex flex-column mb-4 mm-feature-description">
          <Image
            className="mm-feature-image"
            src={Star}
            alt="crypto price bot chart bsc eth heco defi price bot"
          />
          <h4>Keeps your rating high</h4>
          <p>Platforms like CoinGeko and CoinMarketCap use daily volume in their token rating algorithms. By using FiBot you get a better overall rating.</p>
        </Col>
      </Row>
    </Container>
  )
}

export default MmFeatures
