import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import BeforeAfter from "../../images/volume-candles.svg"
import Showcase from "../../images/mm-showcase.svg"

const MmHeader = () => {
  return (
    <Container>
      <Row className="d-flex justify-content-center mt-4 mm-header">
        <Col md={8} className="d-flex justify-content-center p-5">
          <div className="">
            <h2>Tokens with higher volumes have more investors trading them</h2>
            <p>
              Make your charts fluid and your volume active.
              <br />
              Keep your project interesting to investors by using our market
              maker bot.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-around">
        <Col
          md={4}
          className="d-flex justify-content-center mm-header-showcase showcase-img"
        >
          <Image src={BeforeAfter}></Image>
        </Col>
        <Col
          md={4}
          className="d-flex justify-content-center mm-header-showcase px-5"
        >
          <Image src={Showcase}></Image>
        </Col>
      </Row>
    </Container>
  )
}

export default MmHeader
