import React from "react"
import { Container, Col, Row, Accordion } from "react-bootstrap"

function FAQ() {
  return (
    <Container>
      <Row>
        <div className="display-4 col-12 heading mt-3 section-heading">
          <span>FAQs</span>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <Accordion className="faq" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Will anybody be able to tell that I'm using a bot?
              </Accordion.Header>
              <Accordion.Body>
                No. Volume comes from random generated wallets over which you hold full control
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                What networks does the bot work on?
              </Accordion.Header>
              <Accordion.Body>
                ETH, BSC, AVAX, MATIC, METIS, etc. --- Any EVM compatible
                network
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                How many funds do I need to provide for market making?
              </Accordion.Header>
              <Accordion.Body>
                It depends on the volume that you want to achieve and the
                average network fees. You are responsible for providing the bot
                with enough money for the gas fees to work.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What if the network gas price suddenly increases?
              </Accordion.Header>
              <Accordion.Body>
                You can configure the amount you are willing to pay in gas for
                each transaction. If the gas price is too high, the trasnaction
                won't execute. FiBot will automatically go back to work when the
                gas price goes below your limit.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                I still don't understand how random intervals work
              </Accordion.Header>
              <Accordion.Body>
                Say you want to execute a transaction every 1 hour. Even if it
                comes from different wallets, having a transaction every hour at
                :00 might look odd. FiBot will execute one transaction at a
                random time within that hour. For example one transaction will
                be at 9:14 and the next one at 10:37.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>What is the pause feature?</Accordion.Header>
              <Accordion.Body>
                Did the market start to heat up on it's own? That's great news!
                You can request to pause the bot and resume the remaining period
                later.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Can I make a volume of 1 million$?
              </Accordion.Header>
              <Accordion.Body>
                Yes, it is possible. But as a rule, it looks suspicious to users
                and reduces the token’s rating, there is also a risk of losing
                the deposit due to the fact that other trading participants can
                outstrip the bot and sell it dearly and buy from it cheaply by
                manipulating the price.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Is it possible to maintain the rating and trust at Coinmarketcap
                and Coingecko using FiBot?
              </Accordion.Header>
              <Accordion.Body>
                Yes, with the correct settings and when the
                conditions of the rating services are met, you will be able to
                maintain good ratings and trust.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default FAQ
