import React from "react"
import { Container, Col, Row, Image } from "react-bootstrap"
import Circle1 from "../../images/icons/1.svg"
import Circle2 from "../../images/icons/2.svg"
import Circle3 from "../../images/icons/3.svg"

function MmHowItWorks() {
  return (
    <Container className="dark">
      <Row className="g-0">
        <Col md={12}>
          <div className="display-4 col-12 heading mt-3 section-heading">
            <span>Getting started</span>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center g-0">
        <Col md={4} className="d-flex flex-column how-section">
          <Image
            className="how-image"
            src={Circle1}
            alt="custom crypto bot chart bsc eth heco defi price bot"
          />
          <div className="how-description">
            <h5>Request a bot</h5>
            <p>
              Get in touch with us at{" "}
              <a
                href="https://t.me/FiBotDan"
                target="_blank"
                rel="noopener noreferrer"
              >
                @FiBotDan
              </a>
              . We will help you figure out what works best for you, and see if
              we can help you out.
            </p>
          </div>
        </Col>
        <Col md={4} className="d-flex flex-column how-section">
          <Image
            className="how-image"
            src={Circle2}
            alt="custom crypto bot chart bsc eth heco defi price bot"
          />
          <div className="how-description">
            <h5>Provide funds</h5>
            <p>
              Provide the funds for running the market making process by sending
              them to non-custodial wallets that we will be giving to your. You
              will always be in full control of the funds and wallets.
            </p>
          </div>
        </Col>
        <Col md={4} className="d-flex flex-column how-section">
          <Image
            className="how-image"
            src={Circle3}
            alt="custom crypto bot chart bsc eth heco defi price bot"
          />
          <div className="how-description">
            <h5>Enjoy your new chart</h5>
            <p>
              Just sit back and relax while FiBot is working. Now it's time to
              watch your investors gain some confidence and maybe go out and
              atract some new ones.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default MmHowItWorks
